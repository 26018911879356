@import './tools/_mixins';

//-------------- DESKTOP --------------

.cn__gp__geocard{
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 5px 20px rgba(231, 228, 228, 0.5);
    transition: all 0.35s ease;
    background:#FFFFFF !important;
    cursor: pointer;
    a {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &:hover{
        box-shadow: 0 30px 20px -20px rgb(87 75 144 / 15%);
        transform: translateY(-5px);
    }

    &__top{
        position: relative;
        height: 14.5834vw;
        max-height: 210px;

        &__img{
            position: relative;
            width: 100%;
            height: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__amenities{
            position: absolute;
            bottom: 16px;
            right: 20px;
            display: flex;
            align-items: center;
            background: #fff;
            box-shadow: inset 7.7px -7.7px 7.7px rgba(149,149,149,.1), inset -7.7px 7.7px 7.7px rgba(255,255,255,.1);
            backdrop-filter: blur(15.4px);
            border-radius: 30px;
            padding: 9px;
            gap: 10px;
        }

        &__rating{
            // position: absolute;
            // bottom: 16px;
            // left: 20px;
            // display: flex;
            // align-items: center;
            // gap: 5px;
            position: absolute;
            bottom: 20px;
            left: 20px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.70);
            backdrop-filter: blur(2px);
            padding: 3px 12px;
            display: flex;
            column-gap: 12px;
            font-size: 14px;
            line-height: 150%;
            font-weight: 600;
            color: #000000;
            border-radius: 10px;

            &__rate{
                // position: relative;
                // padding: 10px;
                // background: #FFFFFF;
                // border-radius: 10px 10px 10px 5px;
                // font-weight: 600;
                // font-size: 16px;
                // line-height: 1.5;
                // letter-spacing: 0.01em;
                // color: #242526;
                padding: 0;
                background-color: unset;
                display: flex;
                align-items: center;
                color: inherit;
            }

            &__rateinfo{
                // position: relative;
                // color: #FFFFFF;
                // line-height: 1.5;
                // letter-spacing: 0.01em;

                &__title{
                    color: inherit;
                    // font-weight: 600;
                    // font-size: 16px;
                }
                &__subtitle{
                    color: inherit;
                    font-weight: 400;
                    font-size: 12px;
                    // font-weight: 400;
                    // font-size: 14px;
                }
            }
        }

        &__tags{
            position: absolute;
            top: 16px;
            right: 20px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            &__breakfast{
                position: relative;
                padding: 5px 8px;
                border-radius: 4px;
                background: var(--color-light-purple);
                display: flex;
                align-items: center;
                gap: 5px;

                &__title{
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 1.13;
                    letter-spacing: 0.01em;
                    color: var(--color-bright-purple);
                }
            }
            &__free{
                position: relative;
                padding: 5px 8px;
                border-radius: 4px;
                background: var(--color-very-sea-green);
                display: flex;
                align-items: center;
                gap: 5px;

                &__title{
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 1.13;
                    letter-spacing: 0.01em;
                    color: var(--color-very-light-green);
                }
            }
        }
    }

    &__bottom{
        position: relative;
        padding: 15px 20px;
        background: #FFFFFF;
        height: max-content;
        flex: 1;
        display: flex;
        flex-direction: column;

        &__title{
            font-weight: 600;
            font-size: 18px;
            line-height: 1.2;
            color: #242526;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25ch;
        }

        &__rating{
            position: relative;
            border: 0.3px solid #64748B;
            border-radius: 5px;
            padding: 2px 10px;
            margin-top: 10px;
            display: inline-flex;
            align-items: center;
            gap: 5px;
            width: fit-content;

            &__type{
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 0.01em;
                color: #64748B;
            }

            &__stars{
                position: relative;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        &__location{
            position: relative;
            margin-top: 10px;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.01em;
            color: #242526;

            svg{
                position: relative;
                top: 4px;
                flex-shrink: 0;
            }

            &__loc{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }

            &__loc1{
                font-weight: 600;
            }

            &__separator{
                position: relative;
                width: 1px;
                height: 16px;
                background: #242526;
            }
        }

        &__facilitiesprice{
            position: relative;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            &__facilities{
                position: relative;
                display: flex;
                align-items: center;
                gap: 5px;
                flex-wrap: wrap;
                width: 60%;

                &__facility{
                    position: relative;
                    flex-shrink: 0;
                    padding: 4px 8px;
                    background: #FDE9E0;
                    border-radius: 18px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 1.5;
                    letter-spacing: 0.01em;
                    color: #242526;
                }
            }

            &__price{
                position: relative;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 0.01em;
                color: #000000;
                flex-shrink: 0;
                display: flex;
                justify-content: flex-end;
                margin-top: auto;
                span{
                    position: relative;
                    top: 2px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 1.2;
                }
            }
        }
    }
}

//-------------- TABLET / MOBILE --------------

@include tablet {
    .cn__gp__geocard{
        width: 367px !important;
        margin-left: 52px !important;

        &:first-child{
            margin-left: 16px !important;
        }

        &:hover{
            box-shadow: 0 2px 2px rgb(87 75 144 / 10%);
            transform: none;
        }

        &__top{
            height: 25.61vw;
            max-height: 210px;
        }
    }
}

//-------------- MOBILE --------------

@include mobile {
    .cn__gp__geocard{
        width: calc(100vw - 60px) !important;
        margin-left: 33px !important;

        &:first-child{
            margin-left: 11px !important;
        }

        &__top{
            height: 56vw;
            max-height: 210px;
        }
    }
}