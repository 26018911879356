@import './tools/_mixins';
@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__au__card1{
    position: relative;
    background: #FFFFFF !important;
    border: dvw(1) solid rgba(100, 116, 139, 0.5);
    box-sizing: border-box;
    border-radius: dvw(15);
    padding: dvw(40);
    transition: all .4s ease;

    &__icon{
        position: relative;
        width: dvw(48);
        height: dvw(48);
        flex-shrink: 0;
    }

    &__title{
        font-weight: 500;
        font-size: dvw(28);
        line-height: 1.2;
        color: #3A3B6A;
        margin-top: dvw(20);
        pointer-events:none;
    }

    &__subtitle{
        margin-top: dvw(20);
        font-size: dvw(16);
        line-height: 1.5;
        pointer-events:none;
    }

    &:hover{
        transform: translateY(-5px);
        background: #7868C7 !important;

        .cn__au__card1__title, .cn__au__card1__subtitle{
            color: #FFFFFF;
        }
    }
}

@include desktop2{
    .cn__au__card1{
        padding: 40px;
    
        &__icon{
            width: 48px;
            height: 48px
        }
    
        &__title{
            font-size: 28px;
            margin-top: 20px;
        }
    
        &__subtitle{
            margin-top: 20px;
            font-size: 16px;
            text-align: center;
        }
    }
}


//------------------- TABLET/MOBILE -------------------

@include tablet { 
    .cn__au__card1{
        position: relative;
        background: #FFFFFF !important;
        border: 0.5px solid rgba(100, 116, 139, 0.5);
        box-sizing: border-box;
        border-radius: 15px;
        padding: 3vw;
        transition: all .4s ease;
    
        &__icon{
            position: relative;
            width: 3.334vw;
            height: 3.334vw;
            flex-shrink: 0;
        }
    
        &__title{
            font-weight: 600;
            font-size: 2.1vw;
            line-height: 1.2;
            color: #3A3B6A;
            margin-top: 1.3vw;
        }
    
        &__subtitle{
            margin-top: 1.3vw;
            font-size: 1.24vw;
            line-height: 1.5;
        }
    
        &:hover{
            transform: none;
            background: #FFFFFF !important;
            box-shadow: unset;
    
            .cn__au__card1__title{
                color: #3A3B6A;
            }
            .cn__au__card1__subtitle{
                color: #000000;
            }
        }
    }
}

//------------------- MOBILE -------------------

@include mobile {
    .cn__au__card1{
       
        padding: mvw(40);
        width: 359px !important;
        border: mvw(1) solid rgba(100, 116, 139, 0.5);
        height: 400px ;
        box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        &__icon{
            width: mvw(48);
            height: mvw(48);
        }
    
        &__title{
            font-size: 23px;
            margin-top: mvw(20);
        }
    
        &__subtitle{
            margin-top: mvw(20);
            font-size: mvw(16);
        }
        &__button{
            font-weight: 800;
            font-size: 16px;
            color: #F35597;
            text-align: center;
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            border-radius: 0px 0px 20px 20px;
        }
    }
    .cn__card_expanded {
        height: 620px;
    }
}