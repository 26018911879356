@import './tools/_mixins';
@import './tools/_functions';

//------------------- DESKTOP -------------------
.cn__careprogram__banner__wrapper{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    
    .cn__careprogram__banner{
        position: relative;
        width: 100%;
        height: 401px;
        padding: 45px;
        border-radius: 16px;
        overflow: hidden;
        background-color: #7868C7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__infoleft{
            position: relative;
            max-width: 402px;
            color: #fff;
            font-size: 23px;
            line-height: 1.2;
            font-weight: 700;

            span{
                font-weight: 700;
                font-size: 28px;
                line-height: 1.2;
                color: #E86993;
            }
        }

        &__inforight{
            position: relative;
            max-width: 237px;

            &__info{
                position: relative;
                color: #fff;
                font-weight: 700;
                font-size: 28px;
                line-height: 1.2;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }
}


//------------------- TABLET/MOBILE -------------------
@include tablet {
    .cn__careprogram__banner__wrapper{
        padding: 0 30px;
        margin: 60px auto;
        
        .cn__careprogram__banner{
            padding: 40px 50px;
            align-items: flex-end;
    
            &__infoleft{
                top: -28%;
                max-width: 333px;

                span{
                    font-size: 23px;
                }
            }
    
            &__inforight{
                position: relative;
                max-width: 174px;
    
                &__info{
                    font-size: 18px;
                }
            }
        }
    }
}


//------------------- MOBILE -------------------
@include mobile {
    .cn__careprogram__banner__wrapper{
        padding: 0 20px;
        margin: 60px auto;
        
        .cn__careprogram__banner{
            height: auto;
            padding: 69px 60px 32px;
            display: block;
    
            &__infoleft{
                top: unset;
                max-width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: 800;

                span{
                    font-size: inherit;
                    font-weight: 800;
                    color: #F35597;
                }
            }
    
            &__inforight{
                max-width: 100%;
                margin-top: 40px;
    
                &__info{
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                }
            }
        }
    }
}