/* fira-sans - latin */
/* @import url('https://fonts.googleapis.com/css?family=Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap'); */

/* merriweather - latin */
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap'); */

/* Poppins - latin */
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* $primary-font: 'Fira Sans', sans-serif;
$secondary-font: 'Merriweather', serif; */

$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Poppins', sans-serif;;