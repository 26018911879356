@import './tools/_mixins';
@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__aboutus__au9{
    position: relative;
    width: 100%;
    background: #FAFAFA;
    z-index: 1;
    padding: dvw(150) 0;

    &__wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__title{
        font-weight: 600;
        font-size: dvw(32);
        line-height: 1.2;
        text-align: center;
    }

    &__loading{
        position: relative;
        margin-top: dvw(50);

        &__options{
            position: relative;
            display: grid;
            grid-template-columns: repeat(4,minmax(0,1fr));
            grid-column-gap: 18px;

            &__option{
                position: relative;
                width: 100%;
                height: 21.32vw;
                border-radius: 15px;
                background: #f8f2fc;
            }
        }

        &__button{
            position: relative;
            width: 9.098vw;
            height: 3.056vw;
            border-radius: 15px;
            background: #f8f2fc;
            margin: 3.473vw auto 0;
        }
    }

    &__info{
        position: relative;
        margin-top: dvw(50);

        &__options{
            position: relative;
            width: 100%;

            &__option{
                position: relative;
                background: transparent !important;

                a{
                    color: #242526;
                }

                &__img{
                    position: relative;
                    width: 100%;
                    height: dvw(206);
                    border-radius: 15px;
                    overflow: hidden;
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -webkit-transform: translate3d(0, 0, 0);
                    -moz-transform: translate3d(0, 0, 0);
                    transform: translate3d(0,0,0);

                    img{
                        transition: transform 0.6s ease;
                    }
                }

                &__title{
                    font-size: dvw(14);
                    line-height: 1.5;
                    font-weight: 600;
                    margin-top: dvw(10);
                }

                &__subtitle{
                    font-size: dvw(12);
                    line-height: 1.5;
                    margin-top: dvw(10);
                }

                &:hover{
                    .cn__aboutus__au9__info__options__option__img{
                        img{
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }

        &__button{
            position: relative;
            margin-top: dvw(50);
            padding: dvw(10) dvw(20);
            background: linear-gradient(92.14deg,#f35597 1.79%,#f53959 99.81%);
            font-weight: 600;
            font-size: dvw(16);
            line-height: 1;
            text-align: center;
            letter-spacing: .01em;
            color: #fff;
            border-radius: dvw(30);
            cursor: pointer;
            left: 50%;
            transform: translateX(-50%);
            border: none;
        }
    }
}

@include desktop2{
    .cn__aboutus__au9{
        padding: 150px 0;

        &__title{
            font-size: 32px;
        }
    
        &__loading{
            margin-top: 50px;
    
            &__options{

                &__option{
                    height: 307px;
                }
            }
    
            &__button{
                width: 131px;
                height: 44px;
                margin: 50px auto 0;
            }
        }
    
        &__info{
            margin-top: 50px;
    
            &__options{
    
                &__option{
    
                    &__img{
                        height: 206px;
                    }
    
                    &__title{
                        font-size: 14px;
                        margin-top: 10px;
                    }
    
                    &__subtitle{
                        font-size: 12px;
                        margin-top: 10px;
                    }

                    &:hover{
                        .cn__aboutus__au9__info__options__option__img{
                            img{
                                transform: none;
                            }
                        }
                    }
                }
            }
    
            &__button{
                margin-top: 50px;
                padding: 10px 20px;
                font-size: 16px;
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@include tablet { 
    .cn__aboutus__au9{

        &__wrapper{
            padding: 0;
        }

        &__loading{
            margin-top: 3vw;
    
            &__options{
                display: flex;
                padding-left: 30px;
    
                &__option{
                    flex-shrink: 0;
                    width: 79.2vw;
                    height: 30vw;
                }
            }
    
            &__button{
                position: relative;
                width: 20vw;
                height: 3vw;
                margin: 3vw auto 0;
            }
        }
    }
}

//------------------- MOBILE -------------------

@include mobile {
    .cn__aboutus__au9{
        padding: mvw(60) 0;

        &__title{
            font-size: mvw(32);
            text-align: left;
            padding: 0 20px;
        }

        &__loading{
            margin-top: mvw(20);
            overflow: hidden;
    
            &__options{
                display: flex;
                padding-left: 20px;
    
                &__option{
                    flex-shrink: 0;
                    width: 79.2vw;
                    height: 89.07vw;
                }
            }
    
            &__button{
                position: relative;
                width: 34.934vw;
                height: 11.734vw;
                margin: 5.334vw auto 0;
            }
        }

        &__info{
            position: relative;
            margin-top: mvw(20);
    
            &__options{
                position: relative;
                width: 100%;
    
                &__option{
                    width: mvw(297) !important;
    
                    &__img{
                        height: mvw(206);
                    }
    
                    &__title{
                        font-size: mvw(14);
                        margin-top: mvw(10);
                    }
    
                    &__subtitle{
                        font-size: mvw(12);
                        margin-top: mvw(10);
                    }
                }
            }
    
            &__button{
                margin-top: mvw(40);
                padding: mvw(10) mvw(20);
                font-size: mvw(16);
                border-radius: mvw(30);
            }
        }
    }
}