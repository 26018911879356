@import './tools/_mixins';
@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__aboutus__au5{
    position: relative;
    width: 100%;
    background: #FAFAFA;
    z-index: 1;
    color: #000000;
    padding: dvw(150) 0 dvw(228);
    overflow: hidden;

    &__title{
        position: relative;
        text-align: center;
        font-weight: bold;
        font-size: dvw(54);
        line-height: 1.2;
    }

    &__subtitle{
        font-size: dvw(23);
        line-height: 1.2;
        text-align: center;
        max-width: dvw(943);
        margin: dvw(20) auto 0;
    }

    &__slider{
        margin-top: dvw(23);

        &__card{
            position: relative;
            background: rgba(196, 196, 196, 0.2);
            opacity: 0.5;
            border: dvw(1) solid rgba(100, 116, 139, 0.2);
            border-radius: dvw(15);
            padding: dvw(50);
            text-align: center;
            max-width: dvw(504);
            transition: all 1s ease;

            &__title{
                font-weight: 600;
                font-size: dvw(28);
                line-height: 1.2;
                color: #0F294D;
            }

            &__subtitle{
                font-size: dvw(16);
                line-height: 1.5;
                letter-spacing: 0.01em;
                margin-top: dvw(20);
                min-height: dvw(72);

                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }
        }
    }
}

@include desktop2{
    .cn__aboutus__au5{
        padding: 150px 0 200px;

        &__title{
            font-size: 54px;
        }
    
        &__subtitle{
            font-size: 23px;
            max-width: 943px;
            margin: 20px auto 0;
        }
    
        &__slider{
            margin-top: 30px;
    
            &__card{
                padding: 50px;
                max-width: 504px;
                padding: 50px;
    
                &__title{
                    font-size: 28px;
                }
    
                &__subtitle{
                    font-size: 16px;
                    margin-top: 20px;
                    min-height: 72px;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@include mobile {
    .cn__aboutus__au5{
        padding: mvw(60) 0 mvw(94);
    
        &__title{
            text-align: left;
            font-size: mvw(32);
            padding: 0 20px;
        }
    
        &__subtitle{
            font-size: mvw(16);
            line-height: 1.5;
            text-align: left;
            max-width: 100%;
            margin: mvw(20) auto 0;
            padding: 0 48px 0 20px;
        }
    
        &__slider{
            margin-top: mvw(20);
    
            &__card{
                padding: mvw(20);
                width: mvw(278);
                max-width: unset;
                flex-shrink: 0;
                margin-left: mvw(10);
                opacity: 1;
                background: #FFFFFF;
                border: mvw(1) solid rgba(100, 116, 139, 0.5);
                border-radius: mvw(15);
                text-align: left;
    
                &__title{
                    font-size: mvw(23);
                }
    
                &__subtitle{
                    font-size: mvw(16);
                    margin-top: mvw(37);
                    -webkit-line-clamp: 5;
                }
            }
        }
    }
}