@import '/src/styles/settings/colors';
@import '/src/styles/settings/font';

.wrapper {
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25);
    opacity: 0;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-name: fadein;
}

.fadeout {
    animation-name: fadeout;
}

.fadeout .card {
    animation-name: exit;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.overflow_hidden {
    overflow: hidden;
}

.inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    position: relative;
    background-color: $color-light-gray-shade;
    max-width: 90vw;
    width: 800px;
    border-radius: 38px;
    border: 1px solid $color-light-gray-input;
    padding: 52px 116px 52px 46px;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-name: enter;
}

@keyframes enter {
    from {
        transform: translateY(20vh);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes exit {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(20vh);
    }
}

.card_close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.card_close:hover {
    cursor: pointer;
}

.card_title {
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    margin-bottom: 30px;
    color: $color-black;
}

.card_form_ul {
    list-style-type: none;
}
.card_form_item {
    margin-bottom: 34px;
    position: relative;
}
.card_form_label {
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
    color: $color-dark-grey;
    display: inline-block;
    margin-bottom: 5px;
}
.card_form_input {
    width: 100%;
    height: auto;
    padding: 10px 15px;
    border-radius: 100px;
    border: 1px solid $color-field-grey;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    font-family: $primary-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}
.card_form_input::placeholder {
    color: $color-subtle-grey;
}
.card_form_item__symbol {
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 38px;
    right: 10px;
}
.card_form_item__symbol:hover {
    cursor: pointer;
}

.card_form_submit_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 90px;
}
.card_form_submit.card_form_submit.card_form_submit {
    color: $color-white;
    max-width: min-content;
    white-space: nowrap;
    border-radius: 20px;
    min-width: 162px;
}

div.loading {
    height: 24px;
}

.reset_success {
    padding: 20px 20px 70px 20px;
    width: 640px;
    border-radius: 15px;
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tickmark {
            margin-bottom: 20px;
        }
        .text {
            font-size: 16px;
            line-height: 125%;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: center;
            max-width: 340px;
        }
        .link {
            font-size: 14px;
            line-height: 150%;
            font-weight: 400;
            a {
                color: $color-purple;
            }
            a:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .card {
        padding: 24px 24px 48px 24px;
    }
    .card_form_submit_wrapper {
        margin-top: 48px;
    }
    .reset_success {
        padding: 20px;
    }
}
