@import './tools/_mixins';

//-------------- DESKTOP --------------

.cn__gp__geocardstays{
    position: relative;
    border-radius: 20px;
    transition: all 0.35s ease;
    background: linear-gradient(0deg, #FCFCFE, #FCFCFE), #C4C4C4 !important;
    cursor: pointer;

    &:hover{
        box-shadow: 0 30px 20px -20px rgb(87 75 144 / 15%);
        transform: translateY(-5px);
    }

    &__top{
        position: relative;
        height: 11.389vw;
        max-height: 164px;
        background: #CCC;

        &__img{
            position: relative;
            width: 100%;
            height: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__bottom{
        position: relative;
        padding: 15px 20px;

        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.01em;
            color: #242526;
            min-height: var(--min-height1);
        }

        &__subtitle{
            font-weight: 500;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.01em;
            color: #242526;
        }

    }
}

//-------------- TABLET / MOBILE --------------

@include tablet {
    .cn__gp__geocardstays{
        width: 269px !important;
        margin-left: 20px !important;

        &:first-child{
            margin-left: 16px !important;
        }

        &:hover{
            box-shadow: 0 2px 2px rgb(87 75 144 / 10%);
            transform: none;
        }

        &__top{
            height: 20vw;
            max-height: 164px;
        }
    }
}

//-------------- MOBILE --------------

@include mobile {
    .cn__gp__geocardstays{
        width: 62.851vw !important;

        &:first-child{
            margin-left: 12px !important;
        }

        &__top{
            height: 38.318vw;
            max-height: 164px;
        }
    }
}