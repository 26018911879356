@mixin font-weight($fontweight-keyword) {
  $fontweight-conversion-map: (
    thin: 100,
    extra-light: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
  );
  $fontweight-output: $fontweight-keyword;
  @if map-has-key($fontweight-conversion-map, $fontweight-keyword) {
    $fontweight-output: map-get(
      $fontweight-conversion-map,
      $fontweight-keyword
    );
  }
  font-weight: $fontweight-output;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin h1-font() {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: 500;
}

@mixin h2-font() {
  font-size: 1.375rem;
  line-height: 1.625rem;
  font-weight: 500;
}

@mixin h3-font() {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: 500;
  font-variant: small-caps;
  text-transform: lowercase;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      font-size: 1.3rem;
      letter-spacing: 0.5px;
    }
  }
}
@mixin nav-font() {
  font-size: 1.125rem;
  line-height: 1.1875rem;
  font-weight: 400;
  font-variant: all-small-caps;
  text-transform: lowercase;
}

@mixin big-text() {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 300;
}

@mixin body-text() {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
}

@mixin body-emphasized() {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}

@mixin body-long-paragraphs() {
  font-size: 1rem;
  line-height: 1.3125rem;
  font-weight: 300;
}

@mixin small-text() {
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 300;
  font-variant: small-caps;
  text-transform: lowercase;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      font-size: 1.25rem;
      letter-spacing: 0.7px;
    }
  }
}

@mixin supplementary-text() {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-weight: 300;
}

@mixin forms-caption() {
  font-size: 0.75rem;
  line-height: 0.938rem;
  font-weight: 300;
}

@mixin box-shadow() {
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

@mixin box-shadow-bottom() {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
}

@mixin box-shadow-property() {
  -webkit-box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.05);
}

@mixin box-shadow-property-hover() {
  -webkit-box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}

@mixin box-shadow-light() {
  -webkit-box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
  -moz-box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
  box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
}

@mixin box-shadow-none() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin input-common {
  background: $color-white;
  border: 1px solid $color-subtle-gray;
  border-radius: 0.375rem;
  @media screen  and (max-width: 600px) {
    border-radius: 0.5rem;
  }
  color: $color-black;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;
  &.input-empty {
    background: $color-light-gray;
    border: 1px solid $color-light-gray;
  }
  &::-webkit-input-placeholder {
    color: $color-dark-grey;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color-dark-grey;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color-dark-grey;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: $color-dark-grey;
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
}

@mixin full-sub-grid {
    width: 100%;
    display: grid;
    /* @media screen and (min-width: 1919px) {
      grid-template-columns: 290px repeat(7, 1fr 20px) 1fr 290px;
    } */
    @media screen and (min-width: 1136px) /* and (max-width: 1919px) */ {
      grid-template-columns: 50px repeat(7, 1fr 20px) 1fr 50px;
    }
    @media screen and (min-width: 601px) and (max-width: 1136px) {
      grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 30px;
    }
    @media screen  and (max-width: 600px) {
      grid-template-columns: 20px repeat(3, 1fr 10px) 1fr 20px;
    }
}

@mixin img-fluid {
  max-width: 100%;
  height: auto;
}

@mixin input-group-box {
  width: 100%;
  background: $color-white;
  border: 1px solid $color-light-gray;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
  @media screen  and (max-width: 600px) {
    border-radius: 0.5rem;
  }
}

@mixin primary-input {
  font-family: $primary-font;
  background: $color-white;
  border: 1px solid $color-light-gray;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
  @media screen  and (max-width: 600px) {
    border-radius: 0.5rem;
  }
  &.input-empty {
    background: $color-light-gray-input;
    border: 1px solid $color-light-gray-input;
    box-shadow: none;
  }
  @include body-text();
  color: $color-black;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 2.375rem;
  width: 100%;
  &::-webkit-input-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &:invalid {
    background-color: $color-light-gray;
  }
  &:focus {
    border: 1px solid $color-primary;
    outline: none;
  }
  & ~ label {
    @include body-text();
    color: $color-dark;
    position: absolute;
    top: 9px;
    left: 21px;
    transition: all 0.15s ease;
    pointer-events: none;
    @media screen  and (max-width: 600px) {
      top: 13px;
    }
  }
  &:focus ~ label,
  &:not([value=""]) ~ label {
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 300;
    color: $color-dark;
    padding: 0.125rem 0.25rem;
    background: $color-white;
    top: -0.5rem;
    left: 0.375rem;
  }
  &.error-input {
    border: 1px solid $color-danger;
    & ~ label {
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 300;
      color: $color-danger;
      padding: 0.125rem 0.25rem;
      background: $color-white;
      top: -0.5rem;
      left: 0.375rem;
    }
    & ~ .error-span {
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: 300;
      color: $color-danger;
      padding: 0.125rem 0.25rem;
    }
  }
  @media screen  and (max-width: 600px) {
    height: 2.875rem;
  }


}

@mixin primary-textarea {
  font-family: $primary-font;
  font-weight: 300;
  background: $color-white;
  border: 1px solid $color-light-gray;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
  padding: 0.625rem 1.25rem;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  height: 8.75rem;
  overflow: auto;
  @media screen  and (max-width: 600px) {
    border-radius: 0.5rem;
  }
  &::-webkit-input-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: $color-dark;
    opacity: 1;
  }
  &.input-empty {
    background: $color-light-gray;
    border: 1px solid $color-light-gray;
    box-shadow: none;
  }
  @include body-text();
  color: $color-black;
  &:focus {
    border: 1px solid $color-primary;
    outline: none;
  }
}

@mixin transition {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@mixin fadinoutAnimation {
  @keyframes fadinoutAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin fadinout-animation {
  @include fadinoutAnimation;
  -webkit-animation: fadinoutAnimation 1.5s infinite;
  -moz-animation: fadinoutAnimation 1.5s infinite;
  -o-animation: fadinoutAnimation 1.5s infinite;
  animation: fadinoutAnimation 1.5s infinite;
}


@mixin mobile {
  @media (max-width: 600px) { @content; }
}
@mixin tablet {
  @media (max-width:1136px) { @content; }
}
@mixin desktop2 {
  @media (min-width:1441px) and (min-height:900px) { @content; }
}



// margins & paddings

@mixin marginsPaddings {
  $limit: 55;
  @for $i from 0 through $limit {
      .m-#{$i} {
          margin: ($i) + px;
      }
      .mb-#{$i} {
          margin-bottom: ($i) + px;
      }
      .mt-#{$i} {
        margin-top: ($i) + px;
      }
      .ml-#{$i} {
        margin-left: ($i) + px;
      }
      .mr-#{$i} {
        margin-right: ($i) + px;
      }


      .p-#{$i} {
        padding: ($i) + px;
      }
      .pb-#{$i} {
        padding-bottom: ($i) + px;
      }
      .pt-#{$i} {
        padding-top: ($i) + px;
      }
      .pl-#{$i} {
        padding-left: ($i) + px;
      }
      .pr-#{$i} {
        padding-right: ($i) + px;
      }
  }
}
